var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ma-0 pa-0"},[_c('v-row',{staticClass:"py-10 px-8"},[_c('strong',[_vm._v("Filtros")]),_c('div',{staticClass:"mt-4"},[_vm._v(" Fecha de creación "),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Desde","clearable":"","prepend-icon":"mdi-calendar-today","readonly":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filter()}},model:{value:(_vm.quotation.dateCreateFrom),callback:function ($$v) {_vm.$set(_vm.quotation, "dateCreateFrom", $$v)},expression:"quotation.dateCreateFrom"}},on))]}}]),model:{value:(_vm.menuFrom),callback:function ($$v) {_vm.menuFrom=$$v},expression:"menuFrom"}},[_c('v-date-picker',{attrs:{"color":"primary"},on:{"input":function($event){_vm.menuFrom = false}},model:{value:(_vm.quotation.dateCreateFrom),callback:function ($$v) {_vm.$set(_vm.quotation, "dateCreateFrom", $$v)},expression:"quotation.dateCreateFrom"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Hasta","clearable":"","prepend-icon":"mdi-calendar","readonly":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filter()}},model:{value:(_vm.quotation.dateCreateTo),callback:function ($$v) {_vm.$set(_vm.quotation, "dateCreateTo", $$v)},expression:"quotation.dateCreateTo"}},on))]}}]),model:{value:(_vm.menuTo),callback:function ($$v) {_vm.menuTo=$$v},expression:"menuTo"}},[_c('v-date-picker',{attrs:{"color":"primary"},on:{"input":function($event){_vm.menuTo = false}},model:{value:(_vm.quotation.dateCreateTo),callback:function ($$v) {_vm.$set(_vm.quotation, "dateCreateTo", $$v)},expression:"quotation.dateCreateTo"}})],1)],1),(_vm.company==undefined)?_c('v-autocomplete',{attrs:{"items":_vm.companyLists,"item-text":"name","item-value":"id","attach":"","chips":"","label":"Empresa","multiple":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filter()}},model:{value:(_vm.quotation.company_id),callback:function ($$v) {_vm.$set(_vm.quotation, "company_id", $$v)},expression:"quotation.company_id"}},[_c('template',{staticClass:"pa-2",slot:"no-data"},[_vm._v("No existen empresas relacionadas.")])],2):_vm._e(),_c('v-autocomplete',{attrs:{"item-text":"name","items":_vm.contactLists,"item-value":"id","attach":"","chips":"","label":"Contacto","multiple":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filter()}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',{staticClass:"mr-2"},[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.name)+" "),(_vm.quotation.company_id==null || _vm.quotation.company_id.length>1 || _vm.quotation.company_id.length==0)?_c('v-chip',{staticClass:"ml-2",staticStyle:{"font-size":"8px"},attrs:{"small":""}},[_vm._v(_vm._s(_vm.companyName(item.company_id)))]):_vm._e()],1)],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}]),model:{value:(_vm.quotation.contact_id),callback:function ($$v) {_vm.$set(_vm.quotation, "contact_id", $$v)},expression:"quotation.contact_id"}},[_c('template',{staticClass:"pa-2",slot:"no-data"},[_vm._v("No existen contactos relacionados.")])],2),_c('v-autocomplete',{attrs:{"items":_vm.userLists,"item-text":"name","item-value":"id","attach":"","chips":"","label":"Vendedor","multiple":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filter()}},model:{value:(_vm.quotation.user_id),callback:function ($$v) {_vm.$set(_vm.quotation, "user_id", $$v)},expression:"quotation.user_id"}}),_c('v-autocomplete',{attrs:{"item-text":"name","item-value":"id","label":"Producto","multiple":"","clearable":"","items":_vm.productsList,"loading":_vm.isLoadingProducts,"search-input":_vm.searchProducts,"placeholder":"Escribe para buscar o crear","hide-no-data":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filter()},"update:searchInput":function($event){_vm.searchProducts=$event},"update:search-input":function($event){_vm.searchProducts=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.name)+" | "+_vm._s((item.price*1).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}))+" "),_c('div',[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("Categoría:")]),_vm._l((item.categories),function(category,index){return [_c('v-chip',{key:index,staticClass:"ml-2",staticStyle:{"font-size":"8px"},attrs:{"small":""}},[_vm._v(_vm._s(_vm.categoryName(category)))])]})],2),_c('div',[_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("Inventario: "),(item.inventory<1)?_c('strong',{staticStyle:{"color":"red!important"}},[_vm._v(_vm._s(item.inventory))]):_c('strong',{staticStyle:{"color":"green!important"}},[_vm._v(_vm._s(item.inventory))])])])])],1)],1)]}}]),model:{value:(_vm.quotation.items),callback:function ($$v) {_vm.$set(_vm.quotation, "items", $$v)},expression:"quotation.items"}}),_c('v-text-field',{attrs:{"label":"Notas"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filter()}},model:{value:(_vm.quotation.note),callback:function ($$v) {_vm.$set(_vm.quotation, "note", $$v)},expression:"quotation.note"}}),_c('div',{staticClass:"mt-4"},[_vm._v(" Última Actualización "),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Desde","clearable":"","prepend-icon":"mdi-calendar-today","readonly":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filter()}},model:{value:(_vm.quotation.dateUpdateFrom),callback:function ($$v) {_vm.$set(_vm.quotation, "dateUpdateFrom", $$v)},expression:"quotation.dateUpdateFrom"}},on))]}}]),model:{value:(_vm.menuFrom2),callback:function ($$v) {_vm.menuFrom2=$$v},expression:"menuFrom2"}},[_c('v-date-picker',{attrs:{"color":"primary"},on:{"input":function($event){_vm.menuFrom2 = false}},model:{value:(_vm.quotation.dateUpdateFrom),callback:function ($$v) {_vm.$set(_vm.quotation, "dateUpdateFrom", $$v)},expression:"quotation.dateUpdateFrom"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Hasta","clearable":"","prepend-icon":"mdi-calendar","readonly":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filter()}},model:{value:(_vm.quotation.dateUpdateTo),callback:function ($$v) {_vm.$set(_vm.quotation, "dateUpdateTo", $$v)},expression:"quotation.dateUpdateTo"}},on))]}}]),model:{value:(_vm.menuTo2),callback:function ($$v) {_vm.menuTo2=$$v},expression:"menuTo2"}},[_c('v-date-picker',{attrs:{"color":"primary"},on:{"input":function($event){_vm.menuTo2 = false}},model:{value:(_vm.quotation.dateUpdateTo),callback:function ($$v) {_vm.$set(_vm.quotation, "dateUpdateTo", $$v)},expression:"quotation.dateUpdateTo"}})],1)],1)],1),_c('v-card',{staticStyle:{"position":"absolute","bottom":"0","width":"100%"},attrs:{"tile":"","color":"primary"}},[_c('v-list-item',{attrs:{"link":"","dark":""},on:{"click":function($event){return _vm.filter()}}},[_c('v-list-item-content',[_c('strong',[_vm._v("Filtrar (0)")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }